import React from "react";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { Link, PageProps } from "gatsby";
import DocumentationSection from "components/docs/DocumentationSection";
import LINKS from "components/docs/navigation/links";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import FieldMappingOverviewSVG from "../../../assets/images/docs/guides/field-mapping/FieldMappingOverviewSVG.svg";
import { Globe, User } from "lucide-react";

/**
 * Creates an Overview for Field Mapping guide
 */
const FieldMappingOverview = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Field Mapping";
  const description =
    "Map custom fields and other non-mapped data from your users' platforms to target fields on your Merge Common Models";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview" className="mb-9">
        <>
          <p>
            Merge Field Mapping allows you or your users to map data from your users&apos; platforms
            to new fields on your Merge Common Models.
          </p>
          <p>
            <Link to={LINKS.REMOTE_DATA.linkTo}>Remote Data</Link> must be enabled to create target
            fields and mappings on a Common Model. This feature is available to <b>Professional</b>{" "}
            and <b>Enterprise</b> customers.
          </p>
          <div className="w-full items-center justify-center mt-9 flex">
            <FieldMappingOverviewSVG />
          </div>
          <p className="mt-9">There are two ways a Field Mapping can be created:</p>
          <div className="w-full justify-between flex flex-row gap-6">
            <Link
              to={LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_ACROSS_INTEGRATION.linkTo}
              className="w-1/2"
            >
              <div className="rounded-lg px-6 pt-4 pb-5 border border-gray-0 shadow-md">
                <div className="flex flex-row items-center text-black">
                  <Globe size={16} className="mr-1.5" />
                  <div className="text-14 font-semibold">
                    For all Linked Accounts across an integration
                  </div>
                </div>
                <div className="text-14 text-gray-60">
                  Integration-wide mappings allow you to map fields that are not Common Model fields
                  to all Linked Accounts for that integration
                </div>
              </div>
            </Link>
            <Link
              to={LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_FOR_LINKED_ACCOUNT.linkTo}
              className="w-1/2"
            >
              <div className="rounded-lg px-6 pt-4 pb-5 border border-gray-0 shadow-md">
                <div className="flex flex-row items-center text-black">
                  <User size={16} className="mr-1.5" />
                  <div className="text-14 font-semibold">For a specific Linked Account</div>
                </div>
                <div className="text-14 text-gray-60">
                  Linked Account-level mappings allow you to map a custom field that is specific to
                  a Linked Account
                </div>
              </div>
            </Link>
          </div>
        </>
      </DocumentationSection>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </StaticPageContainer>
  );
};

export default FieldMappingOverview;
